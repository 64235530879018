import React, { useState } from 'react';
import {
  Row,
  Col,
  Select,
  Card,
  Form,
  Space,
  message,
  Image,
  Empty,
  Checkbox
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { QrcodeOutlined, DeleteOutlined } from '@ant-design/icons';
import { QrReader } from 'react-qr-reader';
import Layout from '@/common/Layout';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch } from 'react-redux';
import {
  updatebinposition,
  getContainers,
  getbinContainers,
  deletebinContainers,
  getBatchDetails
} from '../../redux/slice';
import moment from 'moment';
import { history } from 'app/history';
import scanning from '../../images/scanning.gif';
import CustomModal from '@/common/CustomModal';
import { getLoggedInUser } from 'utilities/helpers';
import { getProducts } from '@/features/productionLabelGen/redux/slice';

const UpdateBinPage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [openCam, setOpenCam] = useState(false);
  const [openConatCam, setOpenConatCam] = useState(false);
  const [data, setData] = useState([]);
  const [bin, setBin] = useState('');
  const [search, setSearch] = useState('');
  const [binList, setBinList] = useState([]);
  const [containers, setContainers] = useState([]);
  const [binContainers, setBinContainers] = useState([]);
  const [scan, setScan] = useState(false);
  const [bindata, setBindata] = useState(false);
  const [bincard, setBincard] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bindet, setBindet] = useState('');
  const [ismodal, setIsmodal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [binLocationsdata, setBinLocationsdata] = useState([]);

  const { user } = getLoggedInUser();
  console.log(bincard);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  console.log(selectedRowKeys);
  const onFinish = (values) => {
    const batch_numbers = [];
    data?.map((data) => {
      batch_numbers.push(data?.[0]?.id);
    });
    console.log(batch_numbers);
    setScan(true);
    values['batch_numbers'] = batch_numbers;
    values['bin_no'] = bin;
    dispatch(updatebinposition(values)).then(function (response) {
      if (response?.payload?.success) {
        setTimeout(() => {
          console.log(response?.payload?.data);
          form.resetFields();
          setData([]);
          setBin('');

          setBinList(response?.payload?.data?.batch_bin_locations);
          setScan(false);
        }, 2000);
      } else {
        setScan(false);
      }
    });
  };

  const handleBinPosition = () => {
    // console.log(values);
    setScan(true);
    // values['container_numbers'] = selectedRowKeys;
    // values['bin_no'] = bindet;

    const payload = { bin_numbers: selectedRowKeys, bin_no: bindet };
    dispatch(updatebinposition(payload)).then(function (response) {
      if (response?.payload?.success) {
        setTimeout(() => {
          console.log(response?.payload?.data);
          form.resetFields();
          setData([]);
          setBindet('');
          setBinList(response?.payload?.data?.batch_bin_locations);
          setScan(false);
          history.push('/landingpage');
        });
      } else {
        setScan(false);
      }
    });
  };
  const handleError = (err) => {
    console.error(err);
  };
  const handleClose = () => {
    setIsmodal(false);
  };
  const handleSubmit = () => {
    selectedRowKeys;
    // binLocationsdata.containers.includes(selectedRowKeys);

    let filtereditems = [];

    binLocationsdata.containers.map((id) => {
      // filtereditems.push(id.id);
      selectedRowKeys.map((item) => {
        if (id.number === item) {
          filtereditems.push({ container_id: id.id });
        }
      });
    });
    const payload = { remove_containers: filtereditems };
    dispatch(deletebinContainers(payload)).then(function (response) {
      if (response?.payload?.success) {
        message.success('Containers deleted successfully');
        setIsmodal(false);
        history.push('/updatebin');
      }
    });
    console.log('delete', filtereditems);
  };
  const onChange = (values) => {
    const batches = containers?.filter((batch) => batch.id === values);
    console.log(batches);
    if (user?.role !== 'prod_user') {
      if (batches?.[0]?.inhouse_batch_number.length > 0) {
        console.log(batches);
        let duplicate = data?.length
          ? data.filter(
              (person) => person == batches?.[0]?.inhouse_batch_number
            )
          : [];
        duplicate?.length === 0
          ? setData([
              ...data,
              [
                {
                  id: batches?.[0]?.id,
                  batch: batches?.[0]?.inhouse_batch_number,
                  material_id: batches?.[0]?.material?.code,
                  material_name: batches?.[0]?.material?.name
                }
              ]
            ])
          : message.error('This container has already been added');
        form.resetFields();
      }
    } else {
      console.log(data);
      setData([
        ...data,
        [
          {
            id: batches?.[0]?.id,
            batch: batches?.[0]?.batch_no,
            // material_id: batches?.[0]?.material?.code,
            material_name: batches?.[0]?.product_detail?.name
          }
        ]
      ]);
      form.resetFields();
    }
  };

  console.log(data);
  const onChangebin = (values) => {
    setBin(values);

    // if (user?.role === 'prod_user') {
    //   const payload = { search: values };
    //   dispatch(getProducts(payload)).then(function (response) {
    //     if (response?.payload?.success) {
    //       console.log(response?.payload?.data);
    //       form.resetFields();
    //       setBindata(true);
    //       setBinLocationsdata(response?.payload?.data);
    //       setBinList(response?.payload?.data?.batch_bin_locations);
    //     }
    //   });
    // } else {
    const payload = { id: values };
    dispatch(getbinContainers(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        form.resetFields();
        setBindata(true);
        setBinLocationsdata(response?.payload?.data);
        setBinList(
          user?.role === 'prod_user'
            ? response?.payload?.data?.prod_bin_locations
            : response?.payload?.data?.batch_bin_locations
        );
      }
    });
    // }

    form.resetFields();
  };

  const onChangebinnum = (values) => {
    setBindet(values);
    const payload = { id: values };
    dispatch(getbinContainers(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        form.resetFields();
        // setBinList(response?.payload?.data?.containers);
      }
    });
    form.resetFields();
  };

  const onSearch = (value) => {
    if (value?.length > 6) {
      setSearch(value);
      const payload = { search: value };
      user?.role === 'prod_user'
        ? dispatch(getProducts(payload)).then(function (response) {
            if (response?.payload?.success) {
              console.log(response?.payload?.data);
              setContainers(response?.payload?.data);
            }
          })
        : dispatch(getBatchDetails(payload)).then(function (response) {
            if (response?.payload?.success) {
              console.log(response?.payload?.data);
              setContainers(response?.payload?.data);
            }
          });
    }
  };
  const onSearchbin = (value) => {
    if (value?.length > 2) {
      setSearch(value);
      const payload = { id: value };
      dispatch(getContainers(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response?.payload?.data);
          setBinContainers(response?.payload?.data);
        }
      });
    }
  };

  const handleQrScan = async (scanData) => {
    // scanData.stopPropagation();
    if (scanData && scanData !== '') {
      scanData.text
        .slice(scanData.text.lastIndexOf('/') + 1)
        .substring(0, 3) !== 'CSN'
        ? setBin(scanData.text.slice(scanData.text.lastIndexOf('/') + 1))
        : message.error('This scanned Bin  is not valid');
      setOpenCam(false);
    }
  };
  const handleContainerQrScan = async (scanData) => {
    if (scanData && scanData !== '') {
      scanData.text.slice(scanData.text.lastIndexOf('/') + 1).substring(0, 3) ==
      'CSN'
        ? setData([
            ...data,
            scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
          ])
        : message.error('This scanned Container  is not valid');
      setOpenConatCam(false);
    }
  };
  console.log(search);
  console.log(binList);

  const removeConatiner = (id) => {
    const list = [...data];
    list.splice(id, 1);
    setData(list);
  };

  const columns =
    user?.role !== 'prod_user'
      ? [
          {
            title: 'Bin Number',
            dataIndex: 'current_location',
            key: 'current_location',
            width: '150px',
            ellipsis: true,
            sorter: (a, b) =>
              a?.current_location?.localeCompare(b?.current_location),
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Inhouse Batch Number',
            dataIndex: 'number',
            key: 'number',
            width: '150px',
            ellipsis: true,
            sorter: (a, b) => a?.number?.localeCompare(b?.number),
            render: (text, record) => {
              return record?.batch_detail?.inhouse_batch_number;
            },
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },

          {
            title: 'Material Code',
            dataIndex: 'document_verification',
            key: 'document_verification',
            width: '150px',
            ellipsis: true,
            sorter: (a, b) =>
              a?.batch_detail?.material?.name?.localeCompare(
                b?.batch_detail?.material?.name
              ),
            render: (text, record) => {
              return record?.batch_detail?.material?.code;
            },
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Material Name',
            dataIndex: 'document_verification',
            key: 'document_verification',
            width: '150px',
            ellipsis: true,
            sorter: (a, b) =>
              a?.batch_detail?.material?.name?.localeCompare(
                b?.batch_detail?.material?.name
              ),
            render: (text, record) => {
              return record?.batch_detail?.material?.name;
            },
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Date of Receipt',
            dataIndex: 'document_verification',
            key: 'document_verification',
            width: '150px',
            sorter: (a, b) =>
              a?.batch_detail?.material?.createdAt?.localeCompare(
                b?.batch_detail?.material?.createdAt
              ),
            render: (text, record) => {
              return moment(record?.batch_detail?.material?.createdAt).format(
                'DD MMM YYYY'
              );
            },
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          }
        ]
      : [
          {
            title: 'Bin Number',
            dataIndex: 'current_location',
            key: 'current_location',
            width: '150px',
            ellipsis: true,
            sorter: (a, b) =>
              a?.current_location?.localeCompare(b?.current_location),
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Product Batch Number',
            dataIndex: 'prod_batch_no',
            key: 'prod_batch_no',
            width: '150px',
            ellipsis: true,
            sorter: (a, b) => a?.number?.localeCompare(b?.number),
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Product Batch Name',
            dataIndex: 'prod_batch_name',
            key: 'prod_batch_name',
            width: '150px',
            ellipsis: true,
            sorter: (a, b) => a?.number?.localeCompare(b?.number),
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
            render: (text, record) => {
              return record?.prod_dispensing?.product_detail?.name;
            }
          },
          {
            title: 'Date of Receipt',
            dataIndex: 'document_verification',
            key: 'document_verification',
            width: '150px',
            sorter: (a, b) =>
              a?.document_verification?.material?.createdAt?.localeCompare(
                b?.document_verification?.material?.createdAt
              ),
            render: (text, record) => {
              return moment(
                record?.document_verification?.material?.createdAt
              ).format('DD MMM YYYY');
            },
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          }
        ];
  let locale = {
    emptyText: (
      <span>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>There are no containers in this bin</span>}
        />
      </span>
    )
  };

  const handleConfirm = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleDispense = (value) => {
    const payload = { bin_numbers: selectedRowKeys, bin_no: value };
    dispatch(updatebinposition(payload)).then(function (response) {
      if (response?.payload?.success) {
        setTimeout(() => {
          console.log(response?.payload?.data);
          form.resetFields();
          setData([]);
          setBindet('');
          setBinList(response?.payload?.data?.batch_bin_locations);
          setScan(false);
          history.push('/landingpage');
        });
      } else {
        setScan(false);
      }
    });
  };
  console.log(isChecked);
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title="UPDATE BIN POSITION" className={styles.CustomPanel}>
          <Row gutter={24}>
            <Col xs={12} xl={4} span={4} style={{ paddingTop: '11px' }}>
              <h6 className={styles.lable}>Scan or search bin</h6>
            </Col>
            {bin == '' ? (
              <>
                <Col xs={10} xl={6} span={6}>
                  <FormItem
                    name="bin_containers"
                    rules={[
                      {
                        required: false,
                        message: 'Enter damaged containers'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      placeholder="Scan or search bin"
                      optionFilterProp="children"
                      onChange={onChangebin}
                      onSearch={onSearchbin}
                    >
                      {binContainers.map((item, index) => {
                        return (
                          <option value={item.bin_number} key={index}>
                            {item.bin_number}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
                <Col xs={2} xl={2} span={2} md={2} sm={2}>
                  <CustomButton
                    onClick={() => setOpenCam(true)}
                    className={styles.qr}
                  >
                    <QrcodeOutlined onClick={() => setOpenCam(true)} />
                  </CustomButton>
                </Col>
              </>
            ) : (
              <>
                <Col xs={10} xl={6} span={6}>
                  <h6 className={styles.lableValue}>{bin}</h6>
                </Col>
                <Col xs={2} xl={2} span={2}>
                  <DeleteOutlined
                    onClick={() => setBin('')}
                    className={styles.removal}
                  />
                </Col>
              </>
            )}
          </Row>
          <Row gutter={24}>
            <Col xs={12} xl={4} span={4} style={{ paddingTop: '11px' }}>
              <h6 className={styles.lable}>Search Batch number</h6>
            </Col>
            {data?.length
              ? data?.map((item, index) => {
                  return index === 0 ? (
                    <>
                      <Col
                        xs={10}
                        xl={user?.role !== 'prod_user' ? 6 : 8}
                        span={6}
                        key={index}
                      >
                        <h6 className={styles.lableValue}>
                          {item?.[0]?.batch}
                        </h6>
                      </Col>
                      {user?.role !== 'prod_user' ? (
                        <Col xs={10} xl={6} span={6} key={index}>
                          <h6 className={styles.lableValue}>
                            {item?.[0]?.material_id}
                          </h6>
                        </Col>
                      ) : (
                        ''
                      )}
                      <Col
                        xs={10}
                        xl={user?.role !== 'prod_user' ? 6 : 8}
                        span={6}
                        key={index}
                      >
                        <h6 className={styles.lableValue}>
                          {item?.[0]?.material_name}
                        </h6>
                      </Col>
                      <Col xs={2} xl={2} span={2}>
                        <DeleteOutlined
                          onClick={() => removeConatiner(index)}
                          className={styles.removal}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={12} xl={4} span={4}></Col>
                      <Col xs={10} xl={6} span={6} key={index}>
                        <h6 className={styles.lableValue}>
                          {item?.[0]?.batch}
                        </h6>
                      </Col>
                      <Col xs={10} xl={6} span={6} key={index}>
                        <h6 className={styles.lableValue}>
                          {item?.[0]?.material_id}
                        </h6>
                      </Col>
                      <Col xs={10} xl={6} span={6} key={index}>
                        <h6 className={styles.lableValue}>
                          {item?.[0]?.material_name}
                        </h6>
                      </Col>
                      <Col xs={2} xl={2} span={2}>
                        <DeleteOutlined
                          onClick={() => removeConatiner(index)}
                          className={styles.removal}
                        />
                      </Col>
                    </>
                  );
                })
              : ''}
            {data?.length ? (
              <Col xs={12} xl={4} span={4} md={12} sm={12}></Col>
            ) : (
              ''
            )}
            <Col xs={10} xl={6} span={6}>
              <FormItem
                name="batch_number"
                rules={[
                  {
                    required: false,
                    message: 'Enter damaged containers'
                  }
                ]}
                className={styles.customLable}
              >
                {user?.role === 'prod_user' ? (
                  <Select
                    showSearch
                    placeholder="Search product numbers"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                  >
                    {containers.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.batch_no}
                        </option>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    showSearch
                    placeholder="Search batch numbers"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                  >
                    {containers.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.inhouse_batch_number}
                        </option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            </Col>
            {/* <Col xs={2} xl={2} span={2} md={2} sm={2}>
              <CustomButton
                onClick={() => setOpenConatCam(true)}
                className={styles.qr}
              >
                <QrcodeOutlined onClick={() => setOpenConatCam(true)} />
              </CustomButton>
            </Col> */}
          </Row>
          <FormItem className="text-center">
            <Space size={5}>
              {scan ? (
                <Image
                  src={scanning}
                  preview={false}
                  style={{ width: '100px !important', height: '100px' }}
                />
              ) : (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  disabled={data?.length ? false : true}
                >
                  Add & Scan Next Bin
                </CustomButton>
              )}
            </Space>
          </FormItem>
        </Card>
        {bindata ? (
          <>
            <Card
              title={<span>CONTAINER/BIN DETAIL</span>}
              className={`${styles.cardStyles_approver}`}
            >
              <div className={`${styles.table_container}`}>
                <CustomTable
                  className={styles.tableStyles}
                  columns={columns}
                  dataSource={binList}
                  // className={styles.scrolling}
                  rowSelection={rowSelection}
                  rowKey={(record) => record.id}
                  scroll={{ x: 'auto' }}
                  locale={locale}
                />
              </div>
              <FormItem className="text-center">
                <Space>
                  <CustomButton
                    type="primary"
                    ghost
                    onClick={() => handleDispense('Dispensed')}
                  >
                    Move to Dispense
                  </CustomButton>
                  <CustomButton
                    type="primary"
                    ghost
                    onClick={() => handleDispense('Dispensed')}
                  >
                    Move to Sampling
                  </CustomButton>
                  <CustomButton type="primary" onClick={() => setBincard(true)}>
                    Move to Bin
                  </CustomButton>
                  {/* {user?.level === 3 && (
                    <CustomButton
                      type="primary"
                      onClick={() => setIsmodal(true)}
                    >
                      Remove Bin
                    </CustomButton>
                  )} */}
                </Space>
              </FormItem>
            </Card>
            <br />
          </>
        ) : (
          ''
        )}

        {bincard ? (
          <Card className={`${styles.cardStyles_approver1}`}>
            <Row gutter={24}>
              <Col xs={12} xl={16} span={16} style={{ paddingTop: '11px' }}>
                <h6 className={styles.lable}>Scan or search bin</h6>
              </Col>
              {bindet == '' ? (
                <>
                  <Col xs={10} xl={6} span={6}>
                    <FormItem
                      name="bin_containers"
                      rules={[
                        {
                          required: false,
                          message: 'Enter damaged containers'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Scan or Select bin"
                        optionFilterProp="children"
                        onChange={onChangebinnum}
                        onSearch={onSearchbin}
                      >
                        {binContainers.map((item, index) => {
                          return (
                            <option value={item.bin_number} key={index}>
                              {item.bin_number}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={2} xl={2} span={2} md={2} sm={2}>
                    <CustomButton
                      onClick={() => setOpenCam(true)}
                      className={styles.qr}
                    >
                      <QrcodeOutlined onClick={() => setOpenCam(true)} />
                    </CustomButton>
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={10} xl={6} span={6}>
                    <h6 className={styles.lableValue}>{bindet}</h6>
                  </Col>
                  <Col xs={2} xl={2} span={2}>
                    <DeleteOutlined
                      onClick={() => setBindet('')}
                      className={styles.removal}
                    />
                  </Col>
                </>
              )}
            </Row>
            <br />
            <FormItem className="text-center">
              <Space size={5}>
                <CustomButton type="primary" onClick={() => history.back()}>
                  Back
                </CustomButton>
                <CustomButton
                  type="primary"
                  // htmlType="submit"
                  disabled={bin ? false : true}
                  onClick={handleBinPosition}
                >
                  Update Bin
                </CustomButton>
              </Space>
            </FormItem>
          </Card>
        ) : (
          ''
        )}

        {openCam ? (
          <QrReader
            constraints={{ facingMode: 'environment' }}
            delay={1000}
            onError={handleError}
            onResult={(result) => handleQrScan(result)}
            // chooseDeviceId={()=>selected}
            style={{ width: '3px', height: '3px' }}
          />
        ) : (
          ''
        )}
        {openConatCam ? (
          <QrReader
            constraints={{ facingMode: 'environment' }}
            delay={1000}
            onError={handleError}
            onResult={(result) => handleContainerQrScan(result)}
            // chooseDeviceId={()=>selected}
            style={{ width: '3px', height: '3px' }}
          />
        ) : (
          ''
        )}
        <br />
        <FormItem className="text-center">
          <Space size={5}>
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              onClick={() => history.back()}
            >
              Back to Home
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
      {ismodal ? (
        <CustomModal
          // title="Release Notes"
          visible={ismodal}
          width="45%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <Card>
            <h5>
              Are you sure you want to remove the Bin location for the selected
              Containers?
            </h5>
            <FormItem
              name="bin_containers"
              rules={[
                {
                  required: false,
                  message: 'Enter damaged containers'
                }
              ]}
              className={styles.customLable}
            >
              <Checkbox onChange={(e) => handleConfirm(e)} /> Yes, I confirm
              that the selected containers are empty.
            </FormItem>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => handleClose()}
                  type="primary"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  // htmlType="submit"
                  type="primary"
                  onClick={() => handleSubmit()}
                  disabled={!isChecked}
                >
                  Delete
                </CustomButton>
              </Space>
            </FormItem>
          </Card>
        </CustomModal>
      ) : (
        ''
      )}
    </Layout>
  );
};
export default UpdateBinPage;
