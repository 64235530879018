import apiClient from 'utilities/apiClient';

export const createReturns = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.returnable}/${url}`, payload, true);
};

export const getAllreturn = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.returnable}/${url}`, payload, true);
};

export const getAllnonreturn = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.nonreturnable}/${url}`, payload, true);
};

export const createnonReturns = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.nonreturnable}/${url}`,
    payload,
    true
  );
};

export const createReturnsregister = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.returnabledate}/${url}`,
    payload,
    true
  );
};

export const getAllReturnRegister = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.returnabledate}/${url}`,
    payload,
    true
  );
};

export const createnonReturnsregister = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.nonreturnabledate}/${url}`,
    payload,
    true
  );
};

export const getAllnonReturnRegister = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.nonreturnabledate}/${url}`,
    payload,
    true
  );
};

export const createcoldchamber = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.coldchamber}/${url}`, payload, true);
};

export const getcoldchamber = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.coldchamber}/${url}`, payload, true);
};

export const createstretchwrapper = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.wrapper}/${url}`, payload, true);
};

export const getstretchwrapper = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.wrapper}/${url}`, payload, true);
};

export const createprep = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.prep}/${url}`, payload, true);
};

export const getprep = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.prep}/${url}`, payload, true);
};

export const getContainerRooms = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.productDetails}/${url}`,
    payload,
    true
  );
};

export const getContainers = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.productDetails}/${url}`,
    payload,
    true
  );
};

export const inwardContainers = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.productDetails}/${url}`,
    payload,
    true
  );
};
