import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Card, Select, Input, message } from 'antd';
import styles from './index.module.less';
import FormItem from '@/common/FormItem';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { getAllDispenseBooths, getBalance } from '../../redux/slice';
import Layout from '@/common/Layout';
import CustomButton from '@/common/CustomButton';

const weightsView = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [dispenseBooths, setDispenseBooths] = useState([]);
  const [ipAddress, setIpAddress] = useState();
  //   const [balanceName, setBalanceName] = useState('');
  const [balanceCode, setBalanceCode] = useState('');
  const [portID, setPortID] = useState('');
  const [balancetype, setBalanceType] = useState('');
  const [weight, setWeight] = useState('');

  useEffect(() => {
    handleGetDispenseBooths();
  }, []);

  const handleBalance = (e) => {
    let booth_details = dispenseBooths.filter((item) => item?.ip_address == e);
    setIpAddress(e);
    // setBalanceName(booth_details?.[0]?.balance_name);
    setBalanceCode(booth_details?.[0]?.dispense_booth_id);
    setPortID(booth_details?.[0]?.port);
    setBalanceType(booth_details?.[0]?.type);
  };

  const handleDispenseBooth = () => {
    const payload = { ip: ipAddress, type: balancetype, port: portID };
    dispatch(getBalance(payload)).then((response) => {
      if (response?.payload?.success) {
        setWeight(response?.payload?.data);
        message.success(response?.payload?.message);
      }
    });
  };
  const handleGetDispenseBooths = () => {
    dispatch(getAllDispenseBooths()).then((result) => {
      if (result?.payload?.success) {
        setDispenseBooths(result?.payload?.data);
      }
    });
  };

  const onFinish = (values) => {
    console.log(values);
  };
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title={
            <span style={{ fontWeight: 'bold' }}>
              Check Weighing Balance Integration Status
            </span>
          }
          className={styles.CustomPanel}
        >
          <>
            <Row gutter={24}>
              <Col xs={24} xl={12} span={12}>
                <FormItem
                  label="Equipment Name"
                  name="equipment_name"
                  className={styles.customLable}
                  rules={[
                    {
                      required: true,
                      message: 'Please select the equipment name'
                    }
                  ]}
                >
                  <Select
                    placeholder="Select Balance Name"
                    onChange={(e) => handleBalance(e)}
                  >
                    {dispenseBooths?.map((item, index) => {
                      return (
                        <option value={item?.ip_address} key={index}>
                          {item?.balance_name}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={8}>
                <FormItem label="Balance Name" className={styles.customLable}>
                  <Input disabled value={balanceCode || 'NA'} />
                </FormItem>
              </Col>
              <Col xs={24} xl={4} span={4}>
                <CustomButton
                  type="primary"
                  onClick={() => handleDispenseBooth()}
                  style={{ marginTop: '25px' }}
                >
                  Read
                </CustomButton>
              </Col>
              {weight ? (
                <Col xs={24} xl={12} span={12}>
                  <FormItem label="Weight" className={styles.customLable}>
                    <Input disabled value={weight || 'NA'} />
                  </FormItem>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </>
        </Card>
      </Form>
    </Layout>
  );
};
export default weightsView;
