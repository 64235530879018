import React, { useState, useEffect } from 'react';
import styles from '@/common/Layout/index.module.less';
import {
  Row,
  Col,
  Card,
  Radio,
  Space,
  Input,
  Form,
  Empty,
  message
} from 'antd';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
import {
  getAllContainerRooms,
  getAllContainers,
  createLabels
} from '../../redux/slice';
import { QrReader } from 'react-qr-reader';
import { QrcodeOutlined } from '@ant-design/icons';
import CustomButton from '@/common/CustomButton';
import FormItem from '@/common/FormItem';
import CustomTable from '@/common/Content/CustomTable';
import { useLocation } from 'react-router-dom';
import { history } from '@/app/history';
const registerLogBooks = ({ loading }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [openCam, setOpenCam] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [roomNum, setRoomNum] = useState('');
  const [containersData, setContainersData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  useEffect(() => {
    handleGetAllContainerRooms();
  }, []);

  const handleGetAllContainerRooms = () => {
    dispatch(getAllContainerRooms()).then((res) => {
      if (res?.payload?.success) {
        setRooms(res?.payload?.data);
      }
    });
  };

  const handleError = (err) => {
    console.error(err);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleClose = () => {
    history.back();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
    // getCheckboxProps: (record) => ({
    //   disabled: record.status === 'QA Pending', // Disable checkbox if status is approved
    //   name: record.name
    // })
  };

  console.log(containersData, roomNum);
  const onFinish = (values) => {
    console.log(values);
    const payload = {
      id: containersData?.[0]?.id,
      prod_room_id: roomNum,
      status: searchParams.get('type').toLowerCase()
    };
    if (searchParams.get('type') !== 'Inward') {
      payload.prod_containers = selectedRowKeys;
    }
    dispatch(createLabels(payload)).then((res) => {
      if (res?.payload?.success) {
        history.push('/log-books');
        message.success(res?.payload?.message);
      }
    });
  };
  const handleQrScan = async (scanData) => {
    if (scanData && scanData !== '') {
      console.log(scanData);
      const payload = {
        prod_containers: scanData?.text,
        type: searchParams.get('type').toLowerCase()
      };

      dispatch(getAllContainers(payload)).then((res) => {
        if (res?.payload?.success) {
          setContainersData(res?.payload?.data);
        }
      });
      setOpenCam(false);
    }
  };

  const onChange = (data) => {
    setRoomNum(data.target.value);
  };

  const columns = [
    {
      title: 'Container No.',
      dataIndex: 'prod_containers',
      key: 'prod_containers',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Quantity',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Inward by PR Sign/Date',
      dataIndex: 'performed_by',
      key: 'performed_by',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Next Stage',
      dataIndex: 'gross_weight',
      key: 'gross_weight',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    }
  ];

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  return (
    <>
      <Layout sider={false}>
        <Card
          className={`${styles.cardStyles_approver}`}
          title={`${searchParams.get('type')} Register`}
        >
          {/* <div className={`${styles.table_container}`}> */}
          {searchParams.get('type') === 'Inward' ? (
            <>
              <Space>
                <Radio.Group onChange={onChange}>
                  {rooms?.map((room, index) => {
                    return (
                      <Radio
                        value={room?.id}
                        key={index}
                        disabled={containersData?.length}
                      >
                        {room?.room_no
                          .replace(/[^a-zA-Z0-9]/g, '')
                          .toUpperCase()}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Space>
              <br />
              <br />
            </>
          ) : (
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Row gutter={24}>
                <Col xs={4} xl={6} span={2} md={2} sm={2}>
                  <FormItem
                    label="Room number"
                    // name="product_name"
                    rules={[
                      {
                        required: true,
                        message: 'Select Product name'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder=""
                      value={
                        containersData?.[0]?.prod_dispensing_labels?.[0]
                          ?.prod_room_master?.room_no
                      }
                      // disabled={params.get('id') ? dataId : data}
                      disabled
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          )}

          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
              <Col xs={4} xl={2} span={2} md={2} sm={2}>
                <CustomButton
                  className={styles.qrcode}
                  onClick={() => setOpenCam(true)}
                >
                  <QrcodeOutlined />
                </CustomButton>
              </Col>
              <Col span={8}>
                <FormItem
                  label="Product name"
                  // name="product_name"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    value={containersData?.[0]?.product_detail?.name}
                    // disabled={params.get('id') ? dataId : data}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col span={4}>
                <FormItem
                  label="Batch Number"
                  // name="batch_number"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    value={containersData?.[0]?.batch_no}
                    // disabled={params.get('id') ? dataId : data}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col span={4}>
                <FormItem
                  label="Stage"
                  // name="Stage"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    value={
                      containersData?.[0]?.prod_label_stage_master
                        ?.prodlabel_stage_name
                    }
                    // disabled={params.get('id') ? dataId : data}
                    disabled
                  />
                </FormItem>
              </Col>
              <Col span={4}>
                <FormItem
                  label="Next Stage"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    value={
                      containersData?.[0]?.prodlabel_nextstage_master
                        ?.prodlabel_nextstage
                    }
                    // disabled={params.get('id') ? dataId : data}
                    disabled
                  />
                </FormItem>
              </Col>
            </Row>
            <CustomTable
              rowSelection={
                searchParams.get('type') === 'Inward' ? '' : rowSelection
              }
              rowKey={'id'}
              locale={locale}
              loading={loading}
              columns={columns}
              dataSource={containersData?.[0]?.prod_dispensing_labels}
              pagination={{
                size: 'small',
                total: 0 || 0,
                showSizeChanger: false
                // onChange: (page) => {
                //   setPage(page);
                // }
              }}
              className={styles.scrolling}
              // scroll={{ x: 'auto' }}
            />
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={handleClose}
                  type="primary"
                  size="small"
                  ghost
                >
                  Close
                </CustomButton>
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                  size="small"
                >
                  Submit
                </CustomButton>
              </Space>
            </FormItem>
          </Form>
          {/* </div> */}
        </Card>
        {openCam ? (
          <QrReader
            constraints={{
              facingMode: 'environment'
            }}
            delay={1000}
            onError={handleError}
            onResult={handleQrScan}
            style={{
              width: '3px',
              height: '3px'
            }}
          />
        ) : (
          ''
        )}
      </Layout>
    </>
  );
};

export default registerLogBooks;
